.login-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100vh;
  min-width: 1280px;
}

.login-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 80px 56px 24px;
  background: rgba(102, 74, 43, 0.6);
  backdrop-filter: blur(24px);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;

  .login-logo {
    width: 100px;

    img {
      width: 100%;
    }
  }

  .login-box {
    margin-top: 80px;
    width: 340px;
    padding: 24px 32px;
    border-radius: 8px;
    background: #fff;

    &-title {
      margin-bottom: 32px;
      font-weight: bold;
      font-size: 24px;
      color: #000000;
      text-align: center;
    }

    .login-password {
      &:hover,
      &:focus {
        border: 1px solid #c69c6d;
      }
    }
    .ant-input-affix-wrapper {
      background-color: rgba(0, 0, 0, 0.03);
      &.ant-input-outlined {
        border: none;
        box-shadow: none;
        &.ant-input-status-error:not(.ant-input-disabled):focus,
        &.ant-input-status-error:not(.ant-input-disabled):focus-within,
        &.ant-input-status-error:not(.ant-input-disabled) {
          background: rgba(0, 0, 0, 0.03);
        }
      }
      &.ant-input-affix-wrapper-lg {
        height: 40px;
      }
    }
    .ant-input {
      font-size: 14px;

      &::placeholder {
        font-size: 14px;
      }

      &:focus,
      &:hover {
        border: none;
        box-shadow: none;
      }
      //修改input输入框自动填充的背景色
      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px transparent inset !important;
        background: transparent !important;
      }

      &:-internal-autofill-previewed {
        transition: background-color 5000s ease-in-out 0s !important;
      }
      &:-internal-autofill-selected {
        transition: background-color 5000s ease-in-out 0s !important;
        background: transparent !important;
      }
    }

    .ant-form-item-explain {
      padding: 8px 0;
      font-size: 12px;
    }

    .ant-form-item-control {
      line-height: 1;
    }
    .ant-checkbox-wrapper {
      &:not(.ant-checkbox-wrapper-disabled):hover {
        .ant-checkbox-inner {
          border-color: #c69c6d;
        }
        .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
          background-color: #c69c6d;
        }
      }
      .ant-checkbox {
        .ant-checkbox-inner {
          border-radius: 2px;
        }
        &:not(.ant-checkbox-disabled):hover {
          .ant-checkbox-inner {
            border-color: #c69c6d;
          }
        }
      }
      .ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: #c69c6d;
          border-color: #c69c6d;
        }
      }
    }

    .ant-checkbox + span {
      font-size: 13px;
    }

    .login-btn {
      margin-top: 24px;
      width: 100%;
      height: 40px;
      border: none;
      color: #fff;
      background-color: #c69c6d;
      &.ant-btn-primary {
        box-shadow: none;
        background: #c69c6d;
        &:not(:disabled):not(.ant-btn-disabled):hover {
          background: #c69c6d;
        }
      }
    }

    &-footer {
      margin-top: 32px;
      font-size: 12px;
      color: #7f7f7f;
      text-align: center;
    }
    .ant-row {
      display: block;
    }
  }

  .login-footer {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
  }
}
@media screen and (max-height: 720px) {
  .login-wrapper {
    padding: calc(100vh * 0.04) 56px 24px;
    .login-box {
      margin-top: calc(100vh * 0.04);
    }
  }
}
