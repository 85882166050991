@font-face {
  font-family: "iconfont"; /* Project id 4718770 */
  src: url('iconfont.woff2?t=1729654639104') format('woff2'),
       url('iconfont.woff?t=1729654639104') format('woff'),
       url('iconfont.ttf?t=1729654639104') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-shanghu:before {
  content: "\e78c";
}

.icon-kechengguanli:before {
  content: "\e78d";
}

.icon-fuwu:before {
  content: "\e78e";
}

.icon-shezhi:before {
  content: "\e78f";
}

.icon-caigouguanli:before {
  content: "\e790";
}

.icon-shangpinguanli:before {
  content: "\e78b";
}

.icon-wangdian:before {
  content: "\e784";
}

.icon-zuzhi:before {
  content: "\e785";
}

.icon-gongzuotai:before {
  content: "\e786";
}

.icon-caigou:before {
  content: "\e787";
}

.icon-yuyue:before {
  content: "\e788";
}

.icon-quanxian:before {
  content: "\e789";
}

.icon-dingdan:before {
  content: "\e78a";
}

.icon-daohanglogo:before {
  content: "\e775";
}

.icon-yingyongzhongxin:before {
  content: "\e776";
}

.icon-xiaoxituisong:before {
  content: "\e777";
}

.icon-baobiao:before {
  content: "\e778";
}

.icon-kucun:before {
  content: "\e774";
}

.icon-shangpin:before {
  content: "\e779";
}

.icon-caiwu:before {
  content: "\e77a";
}

.icon-sucai:before {
  content: "\e77b";
}

.icon-qudaoshangpin:before {
  content: "\e77c";
}

.icon-chongzhiguanli:before {
  content: "\e77d";
}

.icon-yuangong:before {
  content: "\e77e";
}

.icon-fenrun:before {
  content: "\e77f";
}

.icon-shujudaoru:before {
  content: "\e780";
}

.icon-cuxiao:before {
  content: "\e781";
}

.icon-kecheng:before {
  content: "\e782";
}

.icon-kehu:before {
  content: "\e783";
}

