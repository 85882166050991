html, body{
  width: 100%;
  height: 100%;
}
*, :after, :before{
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}
#root {
  & > div > div.ant-spin-nested-loading {
    height: 100%;
  }
}
